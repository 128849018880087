<template>
  <span class="seo-base hidden"></span>
</template>
<script setup lang="ts">
import type { SeoBaseProps } from "./Base.props"
const props = defineProps<SeoBaseProps>()
const config = useRuntimeConfig()
const route = useRoute()

const baseUrl = config.public.BASE_URL_APP
const canonicalUrl = computed(() =>
  props?.canonical ? `${props?.canonical}` : `${baseUrl}${route.path}`
)

const canonicalFormatted = computed(() =>
  canonicalUrl.value.replace(/\/+$/, "")
)

// Use a regular expression to remove HTML tags from metaDescription:
const descriptionFormatted = computed(() =>
  props?.metaDescription ? props?.metaDescription.replace(/<[^>]*>/g, "") : ""
)

const metaDesc = computed(() => {
  if (props?.metaDescription) {
    return [
      {
        hid: "description",
        name: "description",
        content: descriptionFormatted.value
      }
    ]
  }
  return []
})

const ogItems = computed(() => {
  let arr = []

  if (props?.ogTitle) {
    arr.push({
      hid: "og:title",
      property: "og:title",
      content: props?.ogTitle
    })
  }

  if (props?.ogDescription) {
    arr.push({
      hid: "og:description",
      property: "og:description",
      content: props?.ogDescription ?? ""
    })
  }

  if (props?.ogImage) {
    arr.push({
      hid: "og:image",
      property: "og:image",
      content: props?.ogImage ?? ""
    })
  }

  if (canonicalFormatted.value) {
    arr.push({
      hid: "og:url",
      property: "og:url",
      content: canonicalFormatted.value
    })
    arr.push({
      hid: "twitter:url",
      name: "twitter:url",
      content: canonicalFormatted.value
    })
  }

  if (props?.ogType) {
    arr.push({
      hid: "og:type",
      property: "og:type",
      content: props.ogType
    })
  }

  if (props?.twitterTitle) {
    arr.push({
      hid: "twitter:title",
      name: "twitter:title",
      content: props?.twitterTitle ?? ""
    })
  }

  if (props?.twitterDescription) {
    arr.push({
      hid: "twitter:description",
      name: "twitter:description",
      content: props?.twitterDescription ?? ""
    })
  }

  if (props?.twitterImage) {
    arr.push({
      hid: "twitter:image",
      name: "twitter:image",
      content: props?.twitterImage ?? ""
    })
  }

  return arr
})

const index = computed(() =>
  useRuntimeConfig().public.IS_PRODUCTION !== "yes" || props.noIndex
    ? "noindex"
    : "index"
)
const follow = computed(() => (props.noFollow ? "nofollow" : "follow"))

const noIndexMeta = computed(() => {
  return [
    {
      hid: "robots",
      name: "robots",
      content: `${index.value},${follow.value}`
    }
  ]
})

useHead({
  title: `${props.metaTitle} - Top Farmacia` ?? "",
  meta: [...metaDesc.value, ...ogItems.value, ...noIndexMeta.value],
  link: [
    {
      hid: "canonical",
      rel: "canonical",
      href: canonicalFormatted
    }
  ]
})
</script>
